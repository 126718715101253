export { }

// make types more lightweight
// we can't modify object's prototype sadly
// I don't need `Object` methods anyway!

// Summary: Although prototypes are just objects, they are treated specially
// by JavaScript engines to optimize the performance of method lookups on prototypes.
// Leave your prototypes alone! Or if you really need to touch prototypes,
// then do it before other code runs, so that you at least don’t invalidate all the optimizations in the engine while your code is running.

for (let type of [
	Boolean, Number, BigInt, String, Function,

	Set, Map, WeakSet, WeakMap, Array, ArrayBuffer,

	Error, Event,
]) Object.setPrototypeOf(type.prototype, null)


declare global {
	interface ObjectConstructor {
		newRaw<const T>(t: T): T
	}

	interface Array<T> {
		isEmpty(): boolean
		clear(): ReturnType<Array<T>["splice"]>
		spliceSafe(...params: Parameters<Array<T>["splice"]>): ReturnType<Array<T>["splice"]>
	}

	interface ArrayConstructor {
		newRaw<E>(arr: Array<E>): E[]
	}

	interface JSON {
		parseSafe<T>(json: string): T | null
	}

	interface PromiseConstructor {
		delay(ms: number): Promise<void>
	}

	interface FunctionConstructor {
		NOOP<T>(...args: T[]): T[]
		NOOP_ERR<E extends Error>(err: E): Error
	}

	interface Math {
		getRandomInt(min: number, max: number): number
		clamp(value: number, min: number, max: number): number
	}

	interface String {
		capitalise(): string
	}
}

Object.newRaw = function newRaw<T>(object: T) {
	return Object.assign(Object.create(null), object)
}

Array.prototype.isEmpty = function isEmpty<T>(this: Array<T>) {
	return this.length === 0
}
Array.prototype.clear = function clear<T>(this: Array<T>) {
	return this.spliceSafe(0, this.length)
}
Array.prototype.spliceSafe = function safeSplice<T>(this: Array<T>, ...params: Parameters<Array<T>["splice"]>) {
	if (params[0] == -1) return []
	return this.splice(...params)
}

JSON.parseSafe = function parseSafe<T>(json: string) {
	let result: T | null = null
	try { result = JSON.parse(json) } catch { }

	return result
}

Promise.delay = (ms: number) => new Promise(res => setTimeout(res, ms))

Function.NOOP = (...args) => args
Function.NOOP_ERR = <E extends Error>(_: E) => _

Math.getRandomInt = (min: number, max: number) => Math.floor(Math.random() * (max - min)) + min
Math.clamp = (value: number, min: number, max: number) => Math.max(min, Math.min(value, max))

String.prototype.capitalise = function <T extends string>(this: T) {
	if (this.length === 0)
		return this
	return (this[0].toUpperCase() + this.slice(1)) as Capitalize<typeof this>
}
